<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-5banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">打造透明、高效、稳定的数字化生产管理平台</div>
            <div class="head-explainBox-content1">生产稳定性大幅提升的秘诀</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">在国家“双碳”战略的引领下，产业结构加速升级，加快企业的数字化转型，可助力企业实现可持续发展。作
                为⼀家世界领先的果汁饮料公司，该企业在多个国家拥有多种饮料品牌，包括汽⽔、运动饮料、乳类饮品、果汁、茶和咖啡，业务范围⼴泛。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">近年来，中国⻝品饮料零售市场快速增⻓，⾏业竞争加剧，该客户在⽣产效率上⾯临困境</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">在该客户供应链的管理链路中，企业需要耗费⼤量的⼈⼒物⼒进⾏数据采集及故障维修，来保障稳定⽣产</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">集团需要综合⼤量数据经验进⾏⽣产优化决策，达到降本增效的效果</div>
                    </div>
                </div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="special-style">客户的主要需求：</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">提⾼⽣产的稳定性 - 减少现场⾮计划停机的时间，保证设备稳定;</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">降低⼯⼚的⽣产成本 - 降低⼈⼯成本，节省产线不必要的浪费，节约能源消耗;</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">提⾼供应链整体效率 - 通过现场反馈的问题以及数据，不断地优化整体的运⾏，缩短新品的上市周期。</div></div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 44rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right" style="width:106.7rem">
                <div class="purchase-title" style="display:flex;height: 9.9rem;"><div class="special-style">此解决⽅案是迎合供应链4.0时代与该⼯⼚实际需求相结合的项⽬，将⼯⼚实时⽣产情况和数据流相结合，对设备、资源、效率
                    进⾏有效监控，在优化资源配置、提⾼效率的同时，对可能出现的影响⽣产因素进⾏提前预警，提⾼⻛险防控的有效性：<br/><br/></div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">⽹⻚和APP两种载体来进⾏跨平台的使⽤，能够在多终端设备使⽤；</div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">提供集团驾驶舱，精益看板管理，展示⼯⼚产线上的实时指标数据，以及外围设备的关键指标，帮助⼯⼚管理⼈员能直观及时的查看⼯⼚数据并及时
                    干预；</div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">系统同时设有总部和⼯⼚的切换，总部能够查看所有⼯⼚的关键总结数据，帮助决策层查看⼯⼚的⽣产效率和综合情况；</div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">实现产线停机原因分析，建⽴多维度预警及故障分析体系框架，减少问题发⽣干预的滞后，提⾼⽣产稳定性及⽣产效率。</div></div>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right" style="width:106.7rem">
                <div class="purchase-title" style="display:flex;height: 9.9rem;"><div class="special-style">经过⽐孚与⼯⼚的共同努⼒，客户的生产停机恢复时间有了明显改观和提升。实现产线停机原因分析，提⾼⽣产的稳定性；根据采集数据进⾏组合分析及可视化展示，实现设备、能耗、⼈员
                    透明化管理提供，降低管理成本；同时提供可视化看板及报表⼯具，建⽴故障反馈机制，减少⼈⼯抄表时间，实现⾼效⽣产。<br/><br/></div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="special-style">这⼀解决⽅案使⽣产数字化平台的价值可以量化：</div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">实现数据可视化、透明化管理，数据准确性⾼达 <div class="title-6">100%</div>； </div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">通过ME分析，订单完成率计算，以及⽣产运⾏的展示，⼈员效率提⾼ <div class="title-6"> 50%</div>，ME提⾼ <div class="title-6"> 8%</div>； </div></div>
                <div class="purchase-title" style="display:flex;height: 6rem;"><div class="purchase-doit">●</div><div class="special-style">展示故障停机分析，计划外停机时间减少 <div class="title-6">  10%</div>，实现稳定⽣产。</div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        // width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #E61611;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #E61611 ;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 35.6rem;
        height: 18rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: left;
            width: 29.2rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #E42D29;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 21rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
